import React, {useEffect} from "react";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDropdownInputField, EditFormNumberInputField} from "../Components/ReactTable/EditRecordForm";
import {makeApiCall} from "../../Api/makeApiCall";
import _ from 'lodash';
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import {CopyMarketServicePeriod} from "./CopyMarketServicePeriod";
import IconDropdown from "../Components/IconDropdown";

export const ProjectionFactorsService = () => {
    const [cellOptions, setCellOptions] = React.useState(undefined);
    const [cellToStoresOptions, setCellToStoresOptions] = React.useState(undefined);
    const [legacyPeriodOptions, setLegacyPeriodOptions] = React.useState([]);
    const [legacyPeriod, setLegacyPeriod] = React.useState(undefined);

    const columns = [
        {header: "Projection Factor Id", size: 100, accessorKey: 'projectionFactorId'},
        {header: "Cell", accessorKey: 'cellId'},
        {header: "Description", accessorKey: 'cellDescription', size: 250},
        {header: "Legacy Period", size: 100, accessorKey: 'legacyPeriod'},
        {header: "Store Id", accessorKey: 'storeId'},
        {header: "Factor", accessorKey: 'factor'},
        {header: "Growth", accessorKey: 'growth'},
        {header: "Version", accessorKey: 'version'}
    ]

    useEffect(() => {
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/cells/`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToStores/`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/listGcsFiles?url=gs://spins-edp-uat-services/projectionFactors/`),
        ]).then(([cells, cellsToStoresResults, projectionFactorFiles]) => {
            setCellOptions(_.orderBy(cells.data, 'description').map(cell => ({
                value: cell.cell_id,
                label: `${cell.description} - ${cell.cell_id}`
            })));
            setCellToStoresOptions(cellsToStoresResults.data.map((store) => ({
                value: store.store_id,
                label: `${store.store_name} - ${store.store_id}`,
                cellId: store.cell_id
            })));
            setLegacyPeriodOptions(projectionFactorFiles.map((files) => ({
                value: files.fileName.split('.')[0],
                label: files.fileName.split('.')[0]
            })));
            setLegacyPeriod(projectionFactorFiles[0].fileName.split('.')[0])
        })
    }, [])


    const endpointUrl = legacyPeriod===undefined? undefined : `${process.env.REACT_APP_EDP_API_BASE_URL}/projectionFactorsByPeriod/?legacy_period=${legacyPeriod}`;
    const endpointUrlForPost = legacyPeriod===undefined? undefined :`${process.env.REACT_APP_EDP_API_BASE_URL}/projectionFactorsByPeriod/updateFactors?legacy_period=${legacyPeriod}`;


    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({
        endpointUrl,
        endpointUrlForPost,
        urlContainsParams: true
    });


    async function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        delete newOrUpdatedRow.cellDescription;
        Object.keys(newOrUpdatedRow).forEach(function (key) {
            if (newOrUpdatedRow[key] === undefined || newOrUpdatedRow[key] === "") {
                newOrUpdatedRow[key] = null;
            }
            if (isNaN(newOrUpdatedRow[key]) && !newOrUpdatedRow[key]) {
                newOrUpdatedRow[key] = null;
            }
        });

        await onRowEditConfirmed(newOrUpdatedRow);
    }

    function setNewPeriod() {
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/listGcsFiles?url=gs://spins-edp-uat-services/projectionFactors/`),
        ]).then(([projectionFactorFiles]) => {
            setLegacyPeriodOptions(projectionFactorFiles.map((files) => ({
                value: files.fileName.split('.')[0],
                label: files.fileName.split('.')[0]
            })));
        })
    }

    function StoreIdEditField({id, form}) {
        const cellId = form.watch("cellId")
        const options = cellToStoresOptions.filter(cellToStores => cellToStores.cellId === cellId)
        options.push({value: null, label: "Select an option", cellId:cellId})

        if(cellId){
            const currentStoreId = form.getValues(id)
            const filteredStoreId = options.filter(option => option.value === currentStoreId)
            if(filteredStoreId.length > 0){
                const filteredValue = filteredStoreId[0]
                form.setValue(id, filteredValue.value, { shouldDirty: true, shouldTouch: true });
            }
            else {
                if(form.getFieldState(id).isDirty)
                {
                    form.setValue(id, null, { shouldDirty: true, shouldTouch: true})
                }
            }
        }

        return (
            <EditFormDropdownInputField
                label={"Store Id"}
                id={id}
                options={options}
                form={form}
                isRequired={true}
                {...(form.register(id))}
            />
        );
    }

    return (
        <Page beta name={"Projection Factors"}>
            <div className={'project-factors-page-controls'}>
                <div className={'left-things'}>
                    <div className={'legacy_period-wrapper'}>
                        <SelectDropdown
                            id={'legacy_period_dropdown'}
                            dataTestId={'legacy_period_dropdown'}
                            inputLabel={'Select Period'}
                            selectValue={legacyPeriod}
                            options={legacyPeriodOptions}
                            onChange={(option) => setLegacyPeriod(option.value)}
                        />
                    </div>
                    <IconDropdown iconName={'fa-copy'} title={"Copy To New Period"}>
                        <CopyMarketServicePeriod
                            selectedMarketPeriod={legacyPeriod}
                            onSubmitCallback={setNewPeriod}
                            isLegacyPeriod={true}
                            path={"projectionFactors"}
                        />
                    </IconDropdown>
                </div>
            </div>
            {legacyPeriod && <LoadingWrapper loading={loading || !cellOptions || !cellToStoresOptions}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={columns}
                    editTable={[
                        {id: 'projectionFactorId', Component: args => <EditFormNumberInputField {...args} disabled/>},
                        {id: 'cellId', Component: args => <EditFormDropdownInputField {...args} isRequired options={cellOptions}/>},
                        {id: 'legacyPeriod', defaultValue: legacyPeriod, Component: args => <EditFormNumberInputField {...args} disabled/>},
                        {id: 'storeId', Component: args => <StoreIdEditField {...args}/>},
                        {id: 'factor', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                        {id: 'growth', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                        {id: 'version', Component: args => <EditFormNumberInputField {...args} />},
                    ]}
                    initialState={{pagination: {pageSize: 10}}}
                    onRowEditConfirmed={onRowEditConfirmedWrapper}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>}
        </Page>
    );
}
