import React from 'react';
import _ from 'lodash';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import {getArrayValuesFromOptions, getValueFromOptions} from '../../../utils/maybeAddLabelToOptions';
import * as PropTypes from 'prop-types';
import {BOOLEAN_DROPDOWN_OPTIONS} from "./common_dropdown_options";

function FormLabel(props) {
    return <label htmlFor={props.htmlFor}>{`${props.label}${props.requiredField ? "*" : ""}`}</label>;
}

export function EditFormBooleanDropdownInputField(args) {
    return <EditFormDropdownInputField
        {...args}
        options={BOOLEAN_DROPDOWN_OPTIONS}
    />;
}

export function EditFormDropdownInputField({id, form, options, label, disabled = false, isRequired = false, isClearable = false}) {

    return (
        <Controller
            control={form.control}
            name={id}
            id={id}
            disabled={disabled}
            {...form.register(id, {required: isRequired})}
            render={({field: {onChange, value, name, ref},}) => {
                return (
                    <EditFormLabelErrorWrapper
                        id={id}
                        form={form}
                        label={label}
                        isRequired={isRequired}
                        value={value}
                    >
                        <div data-testid={`${id}-form-dropdown-component-container`} className={'form-dropdown-component-container'}>
                            <Select
                                id={`${id}_dropdown`}
                                classNamePrefix={"edit-form-dropdown"}
                                inputId={id}
                                options={options}
                                onChange={(option) => onChange(option ? option.value : null)}
                                value={getValueFromOptions(value, options)}
                                name={name}
                                isDisabled={disabled}
                                ref={ref}
                                isClearable={isClearable}
                            />
                        </div>
                    </EditFormLabelErrorWrapper>
                );
            }}
        />
    );
}

export function EditFormMultiDropdownInputField({id, form, options, label, disabled = false, isRequired = false}) {

    return (
        <Controller
            control={form.control}
            name={id}
            id={id}
            disabled={disabled}
            {...form.register(id, {required: isRequired})}
            render={({field: {onChange, value, name, ref},}) => {
                return (
                    <EditFormLabelErrorWrapper
                        id={id}
                        form={form}
                        label={label}
                        isRequired={isRequired}
                        value={value}
                    >
                        <div data-testid={`${id}-form-dropdown-component-container`}>
                            <Select
                                id={`${id}_dropdown`}
                                classNamePrefix={"edit-form-dropdown"}
                                inputId={id}
                                options={options}
                                onChange={(option) => {
                                    onChange(option?.map(x=> x?.value));
                                }}
                                value={getArrayValuesFromOptions(value, options)}
                                name={name}
                                isDisabled={disabled}
                                isMulti
                                isClearable={true}
                                ref={ref}
                            />
                        </div>
                    </EditFormLabelErrorWrapper>
                );
            }}
        />
    );
}

export function EditFormLabelErrorWrapper({form, label, id, isRequired = false, value,children}) {

    return <div>
        <FormLabel htmlFor={id} label={label} requiredField={isRequired}/>
        {children}
        <FieldErrorMessage form={form} id={id} label={label} isRequired={isRequired}/>
    </div>
}

export function EditFormTextInputField({id, form, label, disabled = false, isRequired = false}) {
    const formRegisterOptions = {required: isRequired};
    return (
        <EditFormLabelErrorWrapper
            id={id}
            form={form}
            label={label}
            isRequired={isRequired}
        >
            <input
                type={"text"}
                id={id}
                disabled={disabled}
                readOnly={disabled}
                step={"any"}
                className={'edit-form-text-input-field'}
                {...(form.register(id, formRegisterOptions))}/>
        </EditFormLabelErrorWrapper>
    )
}

export function EditFormNumberInputField({id, form, label, disabled = false, isRequired = false}) {
    const formRegisterOptions = {required: isRequired, valueAsNumber: true};
    return (
        <EditFormLabelErrorWrapper
            id={id}
            form={form}
            label={label}
            isRequired={isRequired}
        >
            <input
                type={"number"}
                id={id}
                disabled={disabled}
                readOnly={disabled}
                step={"any"}
                className={'edit-form-number-input-field'}
                {...(form.register(id, formRegisterOptions))}/>
        </EditFormLabelErrorWrapper>
    )
}

export function EditFormDateInputField({id, form, label, disabled = false, isRequired = false}) {
    const formRegisterOptions = {required: isRequired};
    return (
        <EditFormLabelErrorWrapper
            id={id}
            form={form}
            label={label}
            isRequired={isRequired}
        >
            <input
                type={"date"}
                id={id}
                disabled={disabled}
                readOnly={disabled}
                step={"any"}
                {...(form.register(id, formRegisterOptions))}/>
        </EditFormLabelErrorWrapper>
    )
}

function FieldErrorMessage(props) {
    const formState = props.form.formState
    return <>
        {
            props.isRequired && (props.form.getValues()[props.id] !== false && !props.form.getValues()[props.id]) && (
                <div className={'error'}>
                    {`${props.label} is required`}
                </div>
            )
        }
        {
            formState.errors[props.id]?.type === 'custom' && (
                <div className={'error'}>
                    {formState.errors[props.id]?.message.map(x => <div>{x}</div>)}
                </div>
            )
        }
    </>;
}

function FormControls(props) {
    return <div className={'right-things'}>
        <button type="button" className="button modal-button cancel" name="cancel"
                onClick={props.onClick}>Cancel
        </button>
        <button type="submit" className={'button modal-button confirm'} name={'save'}>Save</button>
    </div>;
}

export function EditRecordForm({form, submitUpdatedData, cancellationCallback, columns, editTable}) {
    const columnHeaderLookup = _.chain(columns)
        .groupBy(x => x.id || x.accessorKey)
        .mapValues(x => x[0].header)
        .value();

    return (
        <div className={"edit-modal-data-row"}>
            <form onSubmit={form.handleSubmit(submitUpdatedData)} role={'form'} className={'edit-form'}>
                {
                    editTable
                        .map(({id, Component}) => {
                            return <div className={`edit-form-row edit-row-${id}`} key={id}>
                                <Component
                                    key={id}
                                    id={id}
                                    form={form}
                                    label={columnHeaderLookup[id]}
                                />
                            </div>
                        })
                }
                <FormControls onClick={cancellationCallback}/>
            </form>
        </div>
    )
}

EditRecordForm.propTypes = {
    form: PropTypes.object,
    columns: PropTypes.array,
    submitUpdatedData: PropTypes.func,
    cancellationCallback: PropTypes.func,
    dropdownOptions: PropTypes.object
};

FieldErrorMessage.propTypes = {
    formState: PropTypes.any,
    id: PropTypes.any,
    label: PropTypes.any
};

EditFormDropdownInputField.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.any,
    id: PropTypes.any,
    isRequired: PropTypes.bool,
    options: PropTypes.any,
    label: PropTypes.any
};

EditFormTextInputField.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.any,
    id: PropTypes.any,
    isRequired: PropTypes.bool,
    label: PropTypes.any
};

EditFormNumberInputField.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.any,
    id: PropTypes.any,
    isRequired: PropTypes.bool,
    label: PropTypes.any
};

EditFormDateInputField.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.any,
    id: PropTypes.any,
    isRequired: PropTypes.bool,
    label: PropTypes.any
};

FormControls.propTypes = {onClick: PropTypes.any};

FormLabel.propTypes = {
    htmlFor: PropTypes.string,
    label: PropTypes.any,
    requiredField: PropTypes.any
};
